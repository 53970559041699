<template>
  <div class="gradient-background">
    <div class="tw-grid tw-grid-rows-2 tw-content-between">
      <div class="tw-h-auto tw-w-full">
        <div class="tw-space-y-4 tw-p-10">
          <p
            class="tw-text-5xl tw-font-semibold tw-leading-tight tw-text-white"
          >
            Think Education <br />Think Sproutly.
          </p>
        </div>
      </div>
      <div class="tw-h-[50vh] tw-w-full">
        <img src="~/assets/images/auth/macbook-on-rock.webp" alt="macbook" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.gradient-background {
  background-image: url("~/assets/images/auth/gradient-background.webp");
}
</style>
